import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Spinner from "../spinner/Spinner";
import { useSimpleModal } from "../../components/modalContext/SimpleModalContext";
import { Utils } from "../../Utils";

//onCheckPwd(isValid)
export default function PwdChecker({ email, password, onCheckPwd, className }) {
  console.log("PwdChecker");

  const { t } = useTranslation("common");
  const cls = (className ? className + " " : "") + "pwdChecker";
  const [rules, setRules] = useState(null);
  const [validList, setValidList] = useState(null);
  const [allValid, setAllValid] = useState(null);

  const modal = useSimpleModal();

  //get pwd rules
  useEffect(() => {
    if (email) {
      console.log("pwd rule refresh");
      Utils.formPost("auth/pwdpolicy", { email })
        .then(Utils.parseJson)
        .then(res => {
          let rList = [];
          let vList = [];
          if (res.policies) {
            res.policies.forEach(policy => {
              if (policy.required) {
                vList.push(false);
                if (policy.name === "length") {
                  let lbl = policy.max && policy.max >= policy.min ? t("lbl.pwdMinMax", policy) : t("lbl.pwdMin", policy);
                  rList.push({ lbl, exp: new RegExp(policy.rule) });
                } else if (policy.name === "at least one number") {
                  rList.push({ lbl: t("lbl.pwdNumber"), exp: new RegExp(policy.rule) });
                } else if (policy.name === "at least one upper case") {
                  rList.push({ lbl: t("lbl.pwdUpperCase"), exp: new RegExp(policy.rule) });
                } else if (policy.name === "at least one lower case") {
                  rList.push({ lbl: t("lbl.pwdLowerCase"), exp: new RegExp(policy.rule) });
                } else if (policy.name === "at least one special char") {
                  rList.push({ lbl: t("lbl.pwdSpecialChar"), exp: new RegExp(policy.rule) });
                } else {
                  vList.pop(); //in case have new rules so that the length dont match
                }
              }
            });
          }
          setValidList(vList);
          setRules(rList);
        })
        .catch(err => {
          console.error("err", err);
          modal.errMsg(t("err.oops"), t("err.contactAdmin"));
        });
    }
  }, [email]);

  //check pwd
  useEffect(() => {
    if (rules) {
      let checkList = [];
      rules.forEach(r => checkList.push(r.exp.test(password)));
      setValidList(checkList);
      setAllValid(checkList.indexOf(false) == -1);
    }
  }, [password, rules]);

  //trigger callback
  useEffect(() => {
    onCheckPwd(allValid);
  }, [allValid]);

  if (!rules) {
    return <Spinner isCenter={true} height={"4rem"} />;
  } else if (rules.length === 0 || validList.length === 0) {
    return "";
  } else {
    return (
      <div className={cls}>
        <div className={(allValid ? "greenFont" : "yellowFont") + " bold"}>{t("lbl.pwdContain")}</div>
        {rules.map((r, i) => {
          return validList[i] ? (
            <div key={i} className="greenFont light">
              <span className="icon tick green p-15 me-1"></span>
              {r.lbl}
            </div>
          ) : (
            <div key={i} className="yellowFont light">
              - {r.lbl}
            </div>
          );
        })}
      </div>
    );
  }
}
