import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from "../../../components/apolloClientContext/ApolloClientContext";
import { MS_ENDPOINT, CLIENT_ID, ENV } from "../../../Constants";
import { Utils } from "../../../Utils";

//when 401, will come to this page
export default function Handle401Page() {
  const [done, setDone] = useState(null);
  const navigate = useNavigate();
  const p = useParams();
  const apolloClientContext = useApolloClient();

  function goLogin() {
    if (ENV === "local") {
      Utils.storageDelete("et_method");
      Utils.storageDelete("et_it");
      Utils.storageDelete("et_rt");
    } else {
      Utils.deleteCookie("et_method");
      Utils.deleteCookie("et_it");
      Utils.deleteCookie("et_rt");
    }
    navigate("/login", { replace: true });
  }

  useEffect(() => {
    let idToken, refreshToken, method;
    if (ENV === "local") {
      idToken = Utils.storageGet("et_it");
      refreshToken = Utils.storageGet("et_rt");
      method = Utils.storageGet("et_method");
    } else {
      idToken = Utils.getCookie("et_it");
      refreshToken = Utils.getCookie("et_rt");
      method = Utils.getCookie("et_method");
    }

    if (method) {
      if (!refreshToken) {
        console.log("refresh token not found...");
        goLogin();
      } else if (method === "microsoft") {
        console.log("try to refresh ms token...");
        Utils.refreshMsToken(
          refreshToken,
          res => {
            if (ENV === "local") {
              Utils.storageSave("et_method", "microsoft");
              Utils.storageSave("et_it", res.id_token);
              if (res.refresh_token) {
                Utils.storageSave("et_rt", res.refresh_token);
              }
            } else {
              Utils.setCookie("et_method", "microsoft");
              Utils.setCookie("et_it", res.id_token);
              if (res.refresh_token) {
                Utils.setCookie("et_rt", res.refresh_token);
              }
            }
            apolloClientContext.updateClient("microsoft", res.id_token);
            console.log("token refreshed successfully");
            setDone(true);
          },
          () => {
            console.log("unabled to refresh ms, try slient signIn");
            Utils.msSignIn(true);
          }
        );
      } else if (method === "cognitoToken") {
        console.log("try to refresh cognito token...");
        Utils.formPost("auth/token", { refresh_token: refreshToken })
          .then(Utils.parseJson)
          .then(
            res => {
              if (ENV === "local") {
                Utils.storageSave("et_method", "cognitoToken");
                Utils.storageSave("et_it", res.id_token);
                if (res.refresh_token) {
                  Utils.storageSave("et_rt", res.refresh_token);
                }
              } else {
                Utils.setCookie("et_method", "cognitoToken");
                Utils.setCookie("et_it", res.id_token);
                if (res.refresh_token) {
                  Utils.setCookie("et_rt", res.refresh_token);
                }
              }
              apolloClientContext.updateClient("cognitoToken", res.id_token);
              console.log("token refreshed successfully");
              setDone(true);
            },
            () => {
              console.log("unabled to refresh cognito, go login");
              goLogin();
            }
          );
      } else {
        console.log("method not supported, go sign in...");
        goLogin();
      }
    } else {
      console.log("previous sign in method not found...");
      goLogin();
    }
  }, []);

  useEffect(() => {
    if (done) {
      const path = decodeURIComponent(p.path);
      console.log("redirect to -->", path, p);
      navigate(path); //TODOricky param
    }
  }, [done]);

  return <div></div>;
}
