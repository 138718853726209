import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { logSignInMutation } from "../../../Gql";
import { useApolloClient } from "../../../components/apolloClientContext/ApolloClientContext";
import { MS_ENDPOINT, CLIENT_ID, ENV } from "../../../Constants";
import { Utils } from "../../../Utils";
import { useSimpleModal } from "../../../components/modalContext/SimpleModalContext";

export default function HandleCodePage() {
  console.log("HandleCodePage");

  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { code } = useParams();
  const [done, setDone] = useState(null);
  const codeVerifier = Utils.storageGet("codeVerifier");
  const apolloClientContext = useApolloClient();
  const domain = Utils.getDomain();
  const modal = useSimpleModal();

  const [logSignIn] = useMutation(logSignInMutation);

  function getTokenByMSCode(success, error) {
    let request = new XMLHttpRequest();
    request.open("POST", MS_ENDPOINT + "oauth2/v2.0/token", true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    request.onload = function () {
      try {
        if (request.status == 200) {
          success(JSON.parse(request.response));
        } else {
          error(JSON.parse(request.response));
        }
      } catch (e) {
        error({ error: e });
      }
    };
    request.onerror = function () {
      error({ error: "unexpected error" });
    };
    let body = `grant_type=authorization_code&code=${code}&client_id=${CLIENT_ID}&redirect_uri=${domain}&code_verifier=${codeVerifier}&scope=openid email profile offline_access`;
    request.send(body);
  }

  function errHandler(e) {
    console.error(e);
    modal.errMsg(t("err.general"));
    navigate("/login", { replace: true });
  }

  useEffect(() => {
    getTokenByMSCode(res => {
      Utils.post("auth/oauth/microsoft", { token: res.id_token }).then(() => {
        if (ENV === "local") {
          Utils.storageSave("et_method", "microsoft");
          Utils.storageSave("et_it", res.id_token);
          Utils.storageSave("et_rt", res.refresh_token);
        } else {
          Utils.setCookie("et_method", "microsoft");
          Utils.setCookie("et_it", res.id_token);
          Utils.setCookie("et_rt", res.refresh_token);
        }
        apolloClientContext.updateClient("microsoft", res.id_token);
        setDone(true);
      }, errHandler);
    }, errHandler);
  }, []);

  useEffect(() => {
    if (done) {
      logSignIn();
      navigate("/main/head/home", { replace: true });
    }
  }, [done]);

  return <div></div>;
}
