import Select from "react-dropdown-select";

import "./Dropdown.scss";

//share use
//value or valueObj is for displaying the selected item, if both are provided, only value will be used
export default function Dropdown({ value, valueObj, options, disabled, placeholder, itemRenderer, className, handleColor, onChange }) {
  console.log("Dropdown", value);

  let val = [];
  if (value !== undefined && options) {
    val.push(options.find(o => o.id === value));
  } else if (valueObj) {
    val.push(valueObj);
  }

  return (
    <Select
      className={className ? className + " dropdown" : "dropdown"}
      valueField="id"
      labelField="name"
      values={val}
      disabled={disabled}
      options={options}
      searchable={false}
      itemRenderer={itemRenderer}
      placeholder={placeholder ? placeholder : ""}
      onChange={values => onChange(values && values.length ? values[0] : null)}
      dropdownHandleRenderer={() => <span className={handleColor ? `${handleColor} p-2 icon downArrow` : ""}></span>}
    />
  );
}
