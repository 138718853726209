import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useUser } from "../userContext/UserContext";
import { Utils } from "../../Utils";
import { ACL_ROLE, TEP_DOMAIN } from "../../Constants";

import "./SideMenu.scss";

export default function SideMenu({ className }) {
  console.log("SideMenu");

  const { t } = useTranslation("common");
  const [accessInsight, setAccessInsight] = useState(false);

  const { userRes } = useUser();
  const navigate = useNavigate();

  const cls = className ? `${className} sideMenu` : "sideMenu";
  const pathName = useLocation().pathname.toLowerCase();

  const isHome = pathName.startsWith("/main/head/home");
  const isAccount = pathName.startsWith("/main/head/account");
  const isInsights = pathName.startsWith("/main/head/insights");

  if (userRes?.data) {
    // redirect the user to Dashboard when trying to open a widget on Insights without a proper access right
    if (isInsights && !Utils.isAclRole(userRes.data, ACL_ROLE.dashboardAdmin)) {
      const path = decodeURIComponent("/main/head/dashboard");
      navigate(path);
    }
  }
  return (
    <div className={cls}>
      <div className="overflow-y-auto flex-fill-remain noScrollbar h-100">
        <div className="sideMenuContent light smaller py-45 text-center">
          <div className={"mb-3" + (isHome ? " selected" : "")}>
            <Link className="py-25" to={"/main/head/home"}>
              <div className="icon dashboard pt-25 pb-3 mb-1"></div>
              <span>{t("dashboardPage.title")}</span>
            </Link>
          </div>
          {Utils.isAclRole(userRes.data, ACL_ROLE.dashboardAdmin) && (
            <div className={"mb-3" + (isInsights ? " selected" : "")}>
              <Link className="py-25" to={"/main/head/insights"}>
                <div className="icon insights pt-25 pb-3 mb-1"></div>
                <span>{t("insightsPage.title")}</span>
              </Link>
            </div>
          )}
          {Utils.hasMinAclRole(userRes.data, ACL_ROLE.tenantAdmin) && (
            <div className={"mb-3" + (isAccount ? " selected" : "")}>
              <Link className="py-25" to={"/main/head/account"}>
                <div className="icon admin pt-25 pb-3 mb-1"></div>
                <span>{t("accountPage.title")}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
