import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";

import MainHeader from "../../components/mainHeader/MainHeader";
import SideMenu from "../../components/sideMenu/SideMenu";
import { Utils } from "../../Utils";

import "./PageLayout.scss";

export default function PageLayout() {
  console.log("PageLayout");

  //TODOricky remove scroll event?
  const scrollRef = useRef(null);
  const [scrollEndEvent, setScrollEndEvent] = useState(null);

  useEffect(() => {
    if (scrollRef?.current) {
      const handler = event => {
        if (event.target.scrollTop + event.target.offsetHeight > event.target.scrollHeight - 50) {
          setScrollEndEvent({ id: new Date().getTime() });
        }
      };
      scrollRef?.current?.addEventListener("scroll", handler);
      return () => {
        scrollRef?.current?.removeEventListener("scroll", handler);
      };
    }
  }, [scrollRef]);

  return (
    <div className="pageLayout h-100 d-flex flex-column">
      <MainHeader />
      <div className="flex-fill-remain">
        <div className="h-100 d-flex align-items-stretch">
          <SideMenu className="col-auto d-flex flex-column" />
          <div className="col d-flex flex-column">
            <div ref={scrollRef} className="overflow-y-auto flex-fill-remain">
              {/*<Outlet context={{[isSmall, setIsSmall]}} />*/}
              <Outlet context={{ scrollEndEvent }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
