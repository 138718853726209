import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Modal from "react-modal";

import "react-tooltip/dist/react-tooltip.css";

import PageLayout from "./pages/pageLayout/PageLayout";
import DashboardMainPortalPage from "./pages/mainPortalPublicPage/dashboardPage/dashboardPage";
import HandleCodePage from "./pages/signIn/handleCodePage/HandleCodePage";
import Handle401Page from "./pages/signIn/handle401Page/Handle401Page";
import LoginPage from "./pages/signIn/loginPage/LoginPage";
import { initOneSignal, print } from "./OneSignal";
import { UserProvider, useUser } from "./components/userContext/UserContext";
import { ModalProvider } from "./components/modalContext/ModalContext";
import { ApolloClientProvider } from "./components/apolloClientContext/ApolloClientContext";
import { SimpleModalProvider } from "./components/modalContext/SimpleModalContext";
import RouteElmWrapper from "./components/routeElmWrapper/RouteElmWrapper";
import { Utils } from "./Utils";
import { ACL_ROLE } from "./Constants";
import commonEn from "./translations/commonEn.json";
import commonZh from "./translations/commonZh.json";
import commonCn from "./translations/commonCn.json";
import Spinner from "./components/spinner/Spinner";

import "./variables.scss";
import "./index.scss";
import "./myBootstrap.scss";

const CreateAccPage = React.lazy(() => import("./pages/signIn/createAccPage/CreateAccPage"));
const ActivateAccPage = React.lazy(() => import("./pages/signIn/activateAccPage/ActivateAccPage"));
const ForgotPwdPage = React.lazy(() => import("./pages/signIn/forgotPwdPage/ForgotPwdPage"));
const ResetPwdPage = React.lazy(() => import("./pages/signIn/resetPwdPage/ResetPwdPage"));
const CreatePwdPage = React.lazy(() => import("./pages/signIn/createPwdPage/CreatePwdPage"));
const ProfilePage = React.lazy(() => import("./pages/profilePage/ProfilePage"));
const AccountUserPage = React.lazy(() => import("./pages/accountUserPage/AccountUserPage"));
const InsightsPage = React.lazy(() => import("./pages/insightsPage/insightsPage"));
const DashboardPage = React.lazy(() => import("./pages/dashboardPage/DashboardPage"));

//lang setting
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en",
  resources: {
    en: { common: commonEn },
    zh: { common: commonZh },
    cn: { common: commonCn }
  }
});
Utils.loadLangCode();

//one signal
initOneSignal();
print();

//serve for 2 purposes
//1. redirect to home page for any invalid url
//2. handle redirect from ms login since ms cannot directly go to "/code"
function LandingPage() {
  console.log("LandingPage");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const id = searchParams.get("id");
  useEffect(() => {
    if (code) {
      navigate(`/code/${code}`, { replace: true });
    } else {
      console.log("redirect from landing to home");
      navigate("/main/head/home", { replace: true });
    }
  });

  return <div></div>;
}

Modal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <SimpleModalProvider>
          <ApolloClientProvider>
            <ModalProvider>
              <Routes>
                <Route path="/main" element={<UserProvider />}>
                  <Route path="head" element={<PageLayout />}>
                    <Route
                      path="home"
                      element={
                        <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                          <DashboardPage />
                        </React.Suspense>
                      }
                    />
                    <Route
                      path="account"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.tenantAdmin}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <AccountUserPage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="insights"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.tenantAdmin}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <InsightsPage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="profile"
                      element={
                        <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                          <ProfilePage />
                        </React.Suspense>
                      }
                    />
                    <Route index element={<LandingPage />} />
                  </Route>
                  <Route path="dashboardPublic" element={<DashboardMainPortalPage />} />
                  <Route index element={<LandingPage />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/reset"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <ResetPwdPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/forgot"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <ForgotPwdPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/createPwd/:email/:session/:userpool"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <CreatePwdPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/new"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <CreateAccPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/activate/:email/:token"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <ActivateAccPage />
                    </React.Suspense>
                  }
                />
                <Route path="/code/:code" element={<HandleCodePage />} />
                <Route path="/redirect/:path" element={<Handle401Page />} />
                <Route path="*" element={<LandingPage />} />
              </Routes>
            </ModalProvider>
          </ApolloClientProvider>
        </SimpleModalProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
