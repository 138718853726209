import { createContext, useEffect, useState, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";

import TenantModal from "../mainHeader/TenantModal";
import MsgModal from "../msgModal/MsgModal";
// import ChangeShowAllCardsModal from "../changeShowAllCardsModal/ChangeShowAllCardsModal";
import ChangePasswordModal from "../changePasswordModal/ChangePasswordModal";

export const ModalContext = createContext();

export function ModalProvider({ children }) {
  console.log("ModalProvider");

  const { t } = useTranslation("common");
  const [modalData, setModalData] = useState({});
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    clearTimeout(timer);
    if (modalData.autoClose && modalData.isOpen) {
      setTimer(setTimeout(() => data.close(), 3000));
    }
  }, [modalData]);

  const data = useMemo(() => {
    return {
      open: params => {
        setModalData({ isOpen: true, ...params });
      },
      asyncOpen: params => {
        //only TimerModal, ControlModal, ControlOthersModal support asyncOpen now
        return new Promise((resolve, reject) => {
          setModalData({ resolve, reject, isOpen: true, ...params });
        });
      },
      close: () => setModalData({}),
      turnOnMsg: () => setModalData({ id: "MsgModal", isOpen: true, msg: t("popup.turningOn"), iconClass: "lightOn yellow", autoClose: true, showLoading: true }),
      turnOffMsg: () => setModalData({ id: "MsgModal", isOpen: true, msg: t("popup.turningOff"), iconClass: "leave", autoClose: true, showLoading: true }),
      temperatureLockMsg: minute => setModalData({ id: "TemperatureLockModal", isOpen: true, minute }),
      temperatureMsg: isWarm =>
        setModalData({
          id: "MsgModal",
          isOpen: true,
          msg: t(isWarm ? "popup.warming" : "popup.cooling"),
          iconClass: "aircon yellow",
          autoClose: true,
          showLoading: true
        }),
      sceneMsg: isActivate =>
        setModalData({
          id: "MsgModal",
          isOpen: true,
          msg: t(isActivate ? "popup.activateScene" : "popup.deactivateScene"),
          iconClass: "scene yellow",
          autoClose: true,
          showLoading: true
        })
    };
  }, []);

  return (
    <ModalContext.Provider value={data}>
      {children}
      {modalData.id === "TenantModal" ? <TenantModal {...modalData} /> : null}
      {modalData.id === "MsgModal" ? <MsgModal {...modalData} /> : null}
      {modalData.id === "changePasswordModal" ? <ChangePasswordModal {...modalData} /> : null}
      {modalData.id === "ChangeShowAllCardsModal" ? <ChangeShowAllCardsModal {...modalData} /> : null}
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
