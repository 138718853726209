import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { logSignInMutation } from "../../../Gql";
import { useApolloClient } from "../../../components/apolloClientContext/ApolloClientContext";
import { CLIENT_ID, MS_ENDPOINT, ENV } from "../../../Constants";
import { Utils } from "../../../Utils";
import { useSimpleModal } from "../../../components/modalContext/SimpleModalContext";
import "./LoginPage.scss";

export default function LoginPage() {
  console.log("LoginPage");

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const apolloClientContext = useApolloClient();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwdType, setPwdType] = useState("password");
  const [signingIn, setSigningIn] = useState(false);

  const modal = useSimpleModal();

  const [logSignIn] = useMutation(logSignInMutation);

  function togglePwd() {
    setPwdType(pwdType === "password" ? "text" : "password");
  }

  function loginByEmail() {
    setSigningIn(true);
    Utils.formPost("auth/signin", { password, email, tokens: 1 })
      .then(Utils.parseJson)
      .then(res => {
        setSigningIn(false);
        if (res.errno) {
          console.log("signin error:", res.errno, res.errmsg);
          if (res.errno == 504 || res.errno == 406) {
            modal.errMsg(t("err.pwdTooManyAttempted"));
          } else if (res.errno == 402) {
            modal.errMsg(t("err.checkEmail"));
          } else if (res.errno == 505) {
            modal.errMsg(t("err.invalidLogin"));
          } else if (res.errno == 502) {
            navigate(`/createPwd/${encodeURIComponent(email)}/${res.extra.session}/${res.extra.userpool}`);
          } else {
            modal.errMsg(t("err.generalLoginFail"));
          }
        } else {
          console.log("cognito login success");
          apolloClientContext.updateClient("cognitoToken", res.extra.id_token);
          logSignIn();
          if (ENV === "local") {
            Utils.storageSave("et_method", "cognitoToken");
            Utils.storageSave("et_it", res.extra.id_token);
            Utils.storageSave("et_rt", res.extra.refresh_token);
          } else {
            Utils.setCookie("et_method", "cognitoToken");
            Utils.setCookie("et_it", res.extra.id_token);
            Utils.setCookie("et_rt", res.extra.refresh_token);
          }
          navigate("/main/head/home");
        }
      })
      .catch(err => {
        setSigningIn(false);
        console.error("login err", err);
        modal.errMsg(t("err.generalLoginFail"));
      });
  }

  function loginByMS() {
    setSigningIn(true);
    Utils.msSignIn();
  }

  return (
    <div className="loginPage h-100 d-flex flex-column justify-content-center">
      <div className="loginCard py-5 d-flex flex-column justify-content-between g-5">
        <div></div>
        <div>
          <div className="p-45 logo icon"></div>
          <div className="my-4 text-center bigger bold whiteFont">{t("login.welcomeBack")}</div>
        </div>
        <div className="container">
          <input className="inputBox w-100 mb-3" placeholder={t("lbl.email")} value={email} onChange={e => setEmail(e.target.value)}></input>
          <div className="position-relative mb-2">
            <input
              className="inputBox w-100 pe-5"
              autoComplete="off"
              placeholder={t("lbl.pwd")}
              value={password}
              onChange={e => setPassword(e.target.value)}
              type={pwdType}
            ></input>
            <button className={"btn plain round eye icon p-2" + (pwdType !== "password" ? "" : " on")} onClick={togglePwd}></button>
          </div>
          <div className="mb-4 text-center">
            <button className="btn plain yellow" onClick={() => navigate("/forgot")}>
              {t("login.forgot")}
            </button>
          </div>
        </div>
        <div className="container">
          <button className="btn yellow px-5 my-3 w-100" disabled={!email.trim() || !password.trim() || signingIn} onClick={loginByEmail}>
            {t("login.signIn")}
          </button>
          <div className="text-center my-2">
            <button className="btn plain white small" disabled={signingIn} onClick={loginByMS}>
              <span className="msLogo icon p-3 me-2"></span>
              {t("login.msSignIn")}
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
