import { Navigate } from "react-router-dom";

import { Utils } from "../../Utils";
import { useUser } from "../userContext/UserContext";
import Spinner from "../spinner/Spinner";

export default function RouteElmWrapper({ minRole, children }) {
  console.log("RouteElmWrapper");

  const { userRes } = useUser();

  if (userRes.loading) {
    return <Spinner isCenter={true} height={"15rem"} />;
  } else if (Utils.hasMinAclRole(userRes.data, minRole) || Utils.isAclRole(userRes.data, minRole)) {
    return children;
  } else {
    console.log("no access right, redirect to home");
    return <Navigate to="/main/head/home" />;
  }
}
