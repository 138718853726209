import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSimpleModal } from "../modalContext/SimpleModalContext";
import { useModal } from "../modalContext/ModalContext";
import PwdChecker from "../pwdChecker/PwdChecker";
// import { useMutation } from "@apollo/client";
// import { useMutationErrHandler, updatePasswordMutation } from "../../Gql";
import { removeExternalUserId } from "../../OneSignal";
import { Utils } from "../../Utils";
import { URL, ENV } from "../../Constants";

import "./index.scss";

// //
//mobile use
export default function ChangePasswordModal({ isOpen, userRes }) {
  const { t } = useTranslation("common");
  const modal = useSimpleModal();
  const thisModal = useModal();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isValidFormat, setIsValidFormat] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [password, setPassword] = useState("");
  const handleOk = () => {
    thisModal.close();
  };

  const [form] = Form.useForm();
  const onValuesChange = (changedValues, allValues) => {
    setPassword(!allValues.pwd ? "" : allValues.pwd);
    setCanSubmit(allValues.oldPwd && allValues.pwd === allValues.password);
  };
  const onFinish = values => {
    const { oldPwd } = values;
    setLoading(true);

    Utils.formPost("auth/changepwd", { old_password: oldPwd, new_password: password })
      .then(Utils.parseJson)
      .then(r => {
        if (r.errno) {
          if (r.errno == 504) {
            modal.errMsg(t("err.invalidLogin"));
          } else {
            modal.errMsg(t("err.general"));
          }
          console.log("change pwd error", r.errno, r.errmsg);
        } else {
          thisModal.close();
          if (ENV === "local") {
            Utils.storageDelete("et_method");
            Utils.storageDelete("et_it");
            Utils.storageDelete("et_rt");
          } else {
            Utils.deleteCookie("et_method");
            Utils.deleteCookie("et_it");
            Utils.deleteCookie("et_rt");
          }
          removeExternalUserId();
          window.location = URL + "auth/signout?page=starbucks";
        }
        setLoading(false);
      })
      .catch(e => {
        modal.errMsg(t("err.oops"), t("err.contactAdmin"));
        console.log("change pwd err", e);
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onOk={handleOk} onCancel={handleOk} portalClassName={"changePasswordModal"}>
      <div className="content-container">
        <div className="title text-center">Reset password</div>
        <div>
          <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
            <Form.Item key="oldPwd" name="oldPwd" labelCol={{ span: 0 }} wrapperCol={{ span: 16, offset: 4 }}>
              <Input.Password placeholder={t("lbl.pwd")} />
            </Form.Item>
            <Form.Item key="pwd" name="pwd" labelCol={{ span: 0 }} wrapperCol={{ span: 16, offset: 4 }}>
              <Input.Password placeholder={t("lbl.newPwd")} />
            </Form.Item>
            <Form.Item key="password" name="password" labelCol={{ span: 0 }} wrapperCol={{ span: 16, offset: 4 }}>
              <Input.Password name="confirmPwd" placeholder={t("lbl.confirmPwd")} />
            </Form.Item>
            <PwdChecker email={userRes.data?.email} password={password} onCheckPwd={setIsValidFormat} className="smaller" />
            <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
              <div className="text-center operation-btn">
                <Button disabled={!isValidFormat || !canSubmit} htmlType="submit" loading={loading} type="link">
                  {t("lbl.ok")}
                </Button>
              </div>
              <div
                className="text-center operation-link"
                onClick={() => {
                  thisModal.close();
                  navigate("/forgot");
                }}
              >
                {t("login.forgot")}
              </div>
              <div
                className="text-center operation-link"
                onClick={() => {
                  thisModal.close();
                }}
              >
                {t("lbl.cancel")}
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}
