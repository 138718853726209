import { createContext, useContext, useState, useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { createApolloClient } from "../../Gql";
import { Utils } from "../../Utils";
import { ENV } from "../../Constants";

const ApolloClientContext = createContext();

export function ApolloClientProvider({ children }) {
  console.log("ApolloClientProvider");

  //dummy client, not supppose to use but ApolloProvider dont accept null
  const [client, setClient] = useState(createApolloClient());
  const navigate = useNavigate();

  function goLogin() {
    if (ENV === "local") {
      Utils.storageDelete("et_method");
      Utils.storageDelete("et_it");
      Utils.storageDelete("et_rt");
    } else {
      Utils.deleteCookie("et_method");
      Utils.deleteCookie("et_it");
      Utils.deleteCookie("et_rt");
    }
    navigate("/login", { replace: true });
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.location.pathname.startsWith("/main/")) {
        let refreshToken = ENV === "local" ? Utils.storageGet("et_rt") : Utils.getCookie("et_rt");
        let method = ENV === "local" ? Utils.storageGet("et_method") : Utils.getCookie("et_method");
        if (refreshToken) {
          if (method === "microsoft") {
            console.log("try refresh ms token...");
            Utils.refreshMsToken(
              refreshToken,
              res => {
                if (ENV === "local") {
                  Utils.storageSave("et_method", "microsoft");
                  Utils.storageSave("et_it", res.id_token);
                  if (res.refresh_token) {
                    Utils.storageSave("et_rt", res.refresh_token);
                  }
                } else {
                  Utils.setCookie("et_method", "microsoft");
                  Utils.setCookie("et_it", res.id_token);
                  if (res.refresh_token) {
                    Utils.setCookie("et_rt", res.refresh_token);
                  }
                }
                updateClient("microsoft", res.id_token);
                console.log("refresh token success");
              },
              () => {
                // console.log("unabled to refresh, go login");
                // goLogin();
                console.log("unabled to refresh, try slient signIn...");
                Utils.msSignIn(true);
              }
            );
          } else if (method === "cognitoToken") {
            console.log("try refresh cognito token...");
            Utils.formPost("auth/token", { refresh_token: refreshToken })
              .then(Utils.parseJson)
              .then(
                res => {
                  if (ENV === "local") {
                    Utils.storageSave("et_method", "cognitoToken");
                    Utils.storageSave("et_it", res.id_token);
                    if (res.refresh_token) {
                      //no refresh token returned
                      Utils.storageSave("et_rt", res.refresh_token);
                    }
                  } else {
                    Utils.setCookie("et_method", "cognitoToken");
                    Utils.setCookie("et_it", res.id_token);
                    if (res.refresh_token) {
                      //no refresh token returned
                      Utils.setCookie("et_rt", res.refresh_token);
                    }
                  }
                  updateClient("cognitoToken", res.id_token);
                },
                () => {
                  console.log("unabled to refresh, go login");
                  goLogin();
                }
              );
          }
        }
      }
    }, 60000 * 58);
    return () => {
      clearInterval(timer);
    };
  }, []);

  function updateClient(method, idToken) {
    setClient(createApolloClient(method, idToken));
  }

  return (
    <ApolloClientContext.Provider value={{ updateClient }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloClientContext.Provider>
  );
}

export function useApolloClient() {
  return useContext(ApolloClientContext);
}
